


















import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class TagsSelect extends Vue {
  @Prop() title!: string

  @Prop() options!: Array<SelectOption>

  @Prop() currentTag!: string

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit('onTagSelect')
  onTagSelect(value: SelectOption) {
    return value
  }

  onChange(option: SelectOption['value']) {
    if (!this.disabled) {
      this.onTagSelect(option)
    }
  }

}
