import { PeriodMetricsFields } from "@/includes/types/UsersStatByPeriod/types";
import { UsersAnalysisMetricsEnum } from "@/includes/types/usersStatByPeriod.types";

/**
 * @deprecated
 */
export default function getMetricColor(metric: keyof PeriodMetricsFields):string {
  if (metric === 'reply_count') {
    return 'rgba(var(--a-primary), 1)'
  } else if (metric === 'message_count') {
    return 'rgba(var(--a-warning), 1)'
  } else if (metric === 'trigger_call') {
    return 'rgba(var(--a-dark), 1)'
  } else if (metric === 'reputation_take') {
    return '#546D64'
  } else if (metric === 'reputation_add') {
    return '#4FB286'
  } else if (metric === 'ap_take') {
    return '#5a189a'
  } else if (metric === 'ap_add') {
    return '#9d4edd'
  } else if (metric === 'xp_take') {
    return '#804c36'
  } else if (metric === 'xp_add') {
    return '#e2b58b'
  } else if (metric === 'other_command') {
    return '#EECF6D'
  } else if (metric === 'punish_command_take') {
    return '#a4161a'
  } else if (metric === 'punish_command_add') {
    return '#e56b6f'
  } else if (metric === 'warn_command_take') {
    return '#fb6f92'
  } else if (metric === 'warn_command_add') {
    return '#ffb3c6'
  } else {
    return '#004E89'
  }
}

export function getMetricColorNew(metric: UsersAnalysisMetricsEnum):string {
  if (metric === UsersAnalysisMetricsEnum.ReplyCount) {
    return 'rgba(var(--a-primary), 1)'
  } else if (metric === UsersAnalysisMetricsEnum.MessageCount) {
    return 'rgba(var(--a-warning), 1)'
  } else if (metric === UsersAnalysisMetricsEnum.TriggerCall) {
    return 'rgba(var(--a-dark), 1)'
  } else if (metric === UsersAnalysisMetricsEnum.ReputationTake) {
    return '#546D64'
  } else if (metric === UsersAnalysisMetricsEnum.ReputationAdd) {
    return '#4FB286'
  } else if (metric === UsersAnalysisMetricsEnum.ApTake) {
    return '#5a189a'
  } else if (metric === UsersAnalysisMetricsEnum.ApAdd) {
    return '#9d4edd'
  } else if (metric === UsersAnalysisMetricsEnum.XpTake) {
    return '#804c36'
  } else if (metric === UsersAnalysisMetricsEnum.XpAdd) {
    return '#e2b58b'
  } else if (metric === UsersAnalysisMetricsEnum.OtherCommand) {
    return '#EECF6D'
  } else if (metric === UsersAnalysisMetricsEnum.PunishAdd) {
    return '#a4161a'
  } else if (metric === UsersAnalysisMetricsEnum.PunishTake) {
    return '#e56b6f'
  } else if (metric === UsersAnalysisMetricsEnum.WarnTake) {
    return '#fb6f92'
  } else if (metric === UsersAnalysisMetricsEnum.WarnAdd) {
    return '#ffb3c6'
  } else if (metric === UsersAnalysisMetricsEnum.Toxicity) {
    return '#9cafb7'
  } else if (metric === UsersAnalysisMetricsEnum.Profanity) {
    return '#6b7c83'
  } else if (metric === UsersAnalysisMetricsEnum.SevereToxicity) {
    return '#536267'
  } else if (metric === UsersAnalysisMetricsEnum.ReactionCount) {
    return '#dda26b'
  } else if (metric === UsersAnalysisMetricsEnum.PositiveReactionCount) {
    return '#88b04b'
  } else if (metric === UsersAnalysisMetricsEnum.NegativeReactionCount) {
    return '#cd6133'
  } else if (metric === UsersAnalysisMetricsEnum.NoneReactionCount) {
    return '#aec6cf'
  } else if (metric === UsersAnalysisMetricsEnum.ReactionMessageCount) {
    return '#ea9999'
  } else if (metric === UsersAnalysisMetricsEnum.ReactionMessageCountTake) {
    return '#7c594a'
  } else if (metric === UsersAnalysisMetricsEnum.ReactionCountTake) {
    return  '#cfc493'
  } else if (metric === UsersAnalysisMetricsEnum.PositiveReactionCountTake) {
    return '#2a3747'
  } else if (metric === UsersAnalysisMetricsEnum.NegativeReactionCountTake) {
    return '#8d98b3'
  } else if (metric === UsersAnalysisMetricsEnum.NoneReactionCountTake) {
    return '#D0A69C'
  } else {
    return '#004E89'
  }
}

'#8AAB89'
'#CFA08B'
'#7F99AA'
'#EFD09B'
'#B59C97'

'#95A49B'
'#BC9D80'
'#AFBBBF'
'#D0A69C'
'#CCBBA0'

'#7DA1A9'
'#AD9C85'
'#CBAC96'
'#9EB2B3'
'#DBB79B'

'#2a3747'
'#cd6133'
'#88b04b'
'#ffdac1'
'#476072'
'#ea9999'
'#aec6cf'
'#dda26b'
'#8d98b3'

'#9cafb7'
'#6b7c83'
'#536267'
'#e7b396'
