import { BaseUserInfo } from "@/includes/types/UserProfile/types";

import { ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";

import { ApexOptions } from "apexcharts";

//raw data
export type UsersAnalysisApiRes<T> = T extends UsersAnalysisMode.Total
    ? UsersAnalysisTotal
    : { data: Array<UsersAnalysisUsersRawRecord> }

export type UsersAnalysisUsersRawRecordEvent = {
  begin: string
  end: string
}

export type UsersAnalysisUsersRawRecord = BaseUserInfo & {
  data: Partial<Record<UsersAnalysisMetricsEnum, Record<string, number> | string>>
  events?: Array<UsersAnalysisUsersRawRecordEvent>
}

export type MetricDetails = {
  total: number
  details: Record<string, number>
}

export type UsersAnalysisTotal = {
  count: number
} & Partial<Record<UsersAnalysisMetricsEnum, MetricDetails | string>>

export const enum UsersAnalysisMode {
  Total = 'Total',
  Users = 'Users'
}

export enum UsersAnalysisPeriod {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
  None = 'None', //frontend value
}

export enum UsersAnalysisCategory {
  Enter = "Enter",
  Leave = "Leave",
}

export type UsersAnalysisUserFilter = {
  values: Array<number | string>
  mode: UsersAnalysisUserFilterMode
}

export enum UsersAnalysisUserFilterMode {
  Include = 'Include',
  Exclude = 'Exclude',
}

export enum UsersAnalysisMetricsEnum {
  // сообщения
  ReplyCount = 'ReplyCount',
  MessageCount = 'MessageCount',

  // репутация и очки пользователя
  ReputationTake = 'ReputationTake',
  ReputationAdd = 'ReputationAdd',
  ApTake = 'ApTake',
  ApAdd = 'ApAdd',
  XpTake = 'XpTake',
  XpAdd = 'XpAdd',

  // наказания
  PunishTake = 'PunishTake',
  PunishAdd = 'PunishAdd',
  WarnTake = 'WarnTake',
  WarnAdd = 'WarnAdd',

  // другое
  OtherCommand = 'OtherCommand',
  TriggerCall = 'TriggerCall',

  // токсичность
  Profanity = 'Profanity',
  Toxicity = 'Toxicity',
  SevereToxicity = 'SevereToxicity',

  // реакции
  ReactionCount = 'ReactionCount',
  PositiveReactionCount = 'PositiveReactionCount',
  NegativeReactionCount = 'NegativeReactionCount',
  NoneReactionCount = 'NoneReactionCount',

  ReactionMessageCount = 'ReactionMessageCount',
  ReactionMessageCountTake = 'ReactionMessageCountTake',

  ReactionCountTake = 'ReactionCountTake',
  PositiveReactionCountTake = 'PositiveReactionCountTake',
  NegativeReactionCountTake = 'NegativeReactionCountTake',
  NoneReactionCountTake = 'NoneReactionCountTake',
}

//data
export type UsersAnalysisPagination = {
  order: UsersAnalysisMetricsEnum
  direction: 'Asc' | 'Desc',
  limit: number
  offset: number
}

export type UsersAnalysisReq = {
  chat_id: number
  from: string
  to: string
  metrics: Array<UsersAnalysisMetricsEnum>
  mode: UsersAnalysisMode
  period?: UsersAnalysisPeriod | undefined
  pagination?: UsersAnalysisPagination
  category?: UsersAnalysisCategory
  users?: UsersAnalysisUserFilter
}

export type MetricChartData = {
  title?: string
  metric: UsersAnalysisMetricsEnum
  series: ApexChartSeries | null
  options?: ApexOptions
  total: number | string | null
}

export type UserTableDataRecord = {
  user: BaseUserInfo,
  userActivityChart: {
    series: ApexOptions['series']
  } | null
  metrics: Partial<Record<UsersAnalysisMetricsEnum, MetricChartData>>
}
